
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import SimpleModal from '@/common/SimpleModal.vue';

import Avatar from '@/common/Avatar.vue';
import methods from '@/methods';
import { SimpleModalButton } from '@/interface/other.interface';

@Options({
  components: {
    Avatar,
    SimpleModal,
  },
  computed: {
    ...mapState(['profile', 'hijackingToken']),
  },
})
export default class SettingsWrapper extends Vue {
  public buttons: SimpleModalButton[] = [
    {
      button: 'border',
      title: 'No',
      value: 'no',
    },
    {
      button: 'fill',
      title: 'Yes',
      value: 'yes',
    },
  ];

  public showModalLogOut = false;

  public urlAdmin = `${process.env.VUE_APP_BACKEND_URL}/admin`;

  public handleActionModal(value: 'yes' | 'no'): void {
    if (value === 'yes') {
      this.closeLogOutModal();
      methods.store.logOut();
    } else {
      this.closeLogOutModal();
    }
  }

  public closeLogOutModal(): void {
    this.showModalLogOut = false;
  }
}
